<template>
  <v-row
    dense
    align="center"
  >
    <v-col cols="12">
      <v-text-field
        v-model="crm.title"
        placeholder="Digite o título da atividade"
        label="Título"
        dense
        outlined
        rounded
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <v-select
        v-model="crm.priority_level"
        label="Prioridade"
        :items="levels"
        item-value="value"
        item-text="title"
        dense
        outlined
        rounded
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model="crm.return_date"
        label="Data de retorno"
        placeholder="00/00/0000"
        v-mask="['##/##/####']"
        dense
        outlined
        rounded
        hide-details
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    crm: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    levels: [
      { title: 'Baixa', value: 1 },
      { title: 'Média', value: 2 },
      { title: 'Alta', value: 3 },
    ],
  }),
}
</script>
